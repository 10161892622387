.popoverContainer {
  position: relative;
}

[class*="Dialog"].popover {
  position: absolute;
  background: var(--theme-background-solid);
  max-height: none;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--theme-color);
  margin: 0;
  z-index: 1000;
  min-width: 0;
  cursor: default;
  width: 100vw;
  transform: none;
}

[class*="Dialog"].popover.dark {
  background: var(--ttui_grey-900);
}

[class*="Dialog"].menu {
  padding: 0.25rem 0;
}

[class*="Dialog"].bottomLeft {
  top: 100%;
  right: auto;
  left: 0;
}

[class*="Dialog"].bottomRight {
  top: 100%;
  right: 0;
  left: auto;
}

[class*="Dialog"].bottom {
  top: 100%;
  transform: translate(-50%, 0);
}
