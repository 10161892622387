[class*="Dialog"].sectionNav {
  position: fixed;
  top: unset;
  bottom: 4rem;
  right: 0.675rem;
  left: auto;
  background: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  width: 28.75rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-width: calc(100% - 1.35rem);
  transform: none;
}

.sectionNav[open] {
  display: grid;
}

.sectionNav::backdrop {
  display: none;
}

.fullWidth {
  grid-column: span 2;
  padding: 0.75rem;
}

.backdrop {
  position: fixed;
  inset: 0;
  z-index: 1003;
  background: var(--ttui_grey-900);
  opacity: 0.5;
}

span.mobile {
  display: none;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }

  span.mobile {
    display: initial;
  }
}
