$character-avatar--portrait-border: $ct--theme-color !default;

.ddbc-character-avatar {
  z-index: 1;

  &__portrait {
    border-radius: 3px;
    border: 2px solid $character-avatar--portrait-border;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: center center #111 no-repeat;
    background-size: cover;
    object-fit: cover;

    &--none {
      background-image: url("#{$imageURL-local}/characters/default-avatar-builder.png");
    }
  }
}
