.class-manager {
  margin: 20px 0 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 4px;
  position: relative;

  .detail-choice {
    margin-top: 10px;
  }

  &-features {
    &-header {
      display: flex;
      margin-bottom: 5px;
      margin-top: 5px;
      align-items: center;
      font-weight: bold;
    }

    &-heading {
      font-size: 16px;
      font-family: $condensed;
      cursor: pointer;
    }

    &-trigger {
      min-width: 16px;
      height: 16px;
      width: 16px;
      background: transparent 0 0
        url("#{$imageURL-local}/sprites/charsheet-atlas.svg") no-repeat;
      background-size: cover;
      margin-left: 9px;
      cursor: pointer;
    }

    &-opened &-trigger {
      background-position: -17px 0;
    }

    &-collapsed &-trigger {
      background-position: 0 0;
    }
  }

  &-feature {
    &-spellcasting {
      margin-bottom: 10px;
    }

    &-multiclass-description {
      margin: 10px 0;
    }
  }

  &-features-group {
    margin-top: 15px;

    &-header {
      display: flex;
      margin: 5px 0;
      align-items: center;
      font-weight: bold;
    }

    &-heading {
      font-size: 16px;
      font-family: $condensed;
    }

    &-trigger {
      min-width: 16px;
      height: 16px;
      width: 16px;
      background: transparent 0 0
        url("#{$imageURL-local}/sprites/charsheet-atlas.svg") no-repeat;
      background-size: cover;
      margin-left: 9px;
      cursor: pointer;
    }

    &-opened &-trigger {
      background-position: -17px 0;
    }

    &-collapsed &-trigger {
      background-position: 0 0;
    }
  }

  &-feature-infusion {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eaeaea;

    &-header {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &-tabs {
    padding-top: 0;
    margin-top: 10px;
  }
}
