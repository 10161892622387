@import "~@dndbeyond/waterdeep-vars/dist/sass/common-vars";
@import "./vars-tools.scss";
@import "./missingStyle.scss";

/** Mixins **/
@import "./character-tools/mixins/shared.scss";

@import "../../components/Accordion/Accordion";
@import "../js/commonComponents/Tooltip/Tooltip";

@import "../js/smartComponents/AbilitySummary/AbilitySummary";
@import "../js/smartComponents/ArmorClassBox/ArmorClassBox";
@import "../js/smartComponents/AttunementSlot/AttunementSlot";
@import "../js/smartComponents/BoxBackground/BoxBackground";
@import "../js/smartComponents/CampaignSummary/CampaignSummary";
@import "../js/smartComponents/CharacterAvatar/CharacterAvatar";
@import "../js/smartComponents/CharacterProgressionSummary/CharacterProgressionSummary";
@import "../js/smartComponents/CharacterSummary/CharacterSummary";
@import "../js/smartComponents/CharacterTidbits/CharacterTidbits";
@import "../js/smartComponents/Checkbox/Checkbox";
@import "../js/smartComponents/Collapsible/Collapsible";
@import "../js/smartComponents/CombatAttack/CombatAttack";
@import "../js/smartComponents/ConditionName/ConditionName";
@import "../js/smartComponents/CreatureList/CreatureList";
@import "../js/smartComponents/CreaturePreview/CreaturePreview";
@import "../js/smartComponents/Damage/Damage";
@import "../js/smartComponents/DamageAdjustment/DamageAdjustment";
@import "../js/smartComponents/DataOriginName/DataOriginName";
@import "../js/smartComponents/DiceRollGroup/DiceRollGroup";
@import "../js/smartComponents/DiceRollGroupManager/DiceRollGroupManager";
@import "../js/smartComponents/DiceRollGroup/DiceRoll/DiceRoll";
@import "../js/smartComponents/ExclusiveCheckbox/ExclusiveCheckbox";
@import "../js/smartComponents/Icons/AdvantageIcon/AdvantageIcon";
@import "../js/smartComponents/Icons/AdvantageDisadvantageIcon/AdvantageDisadvantageIcon";
@import "../js/smartComponents/Icons/AoeTypeIcon/AoeTypeIcon";
@import "../js/smartComponents/Icons/ConditionIcon/ConditionIcon";
@import "../js/smartComponents/Icons/ResistanceIcon/ResistanceIcon";
@import "../js/smartComponents/Icons/VulnerabilityIcon/VulnerabilityIcon";
@import "../js/smartComponents/Icons/ImmunityIcon/ImmunityIcon";
@import "../js/smartComponents/Icons/AttackTypeIcon/AttackTypeIcon";
@import "../js/smartComponents/Icons/AttunementIcon/AttunementIcon";
@import "../js/smartComponents/Icons/DamageTypeIcon/DamageTypeIcon";
@import "../js/smartComponents/Icons/DisadvantageIcon/DisadvantageIcon";
@import "../js/smartComponents/Icons/HealingIcon/HealingIcon";
@import "../js/smartComponents/Icons/ManageIcon/ManageIcon";
@import "../js/smartComponents/Icons/ProficiencyLevelIcon/ProficiencyLevelIcon";
@import "../js/smartComponents/Icons/TodoIcon/TodoIcon";
@import "../js/smartComponents/Icons/SpellSchoolIcon/SpellSchoolIcon";
@import "../js/smartComponents/InfusionPreview/InfusionPreview";
@import "../js/smartComponents/ItemPreview/ItemPreview";
@import "../js/smartComponents/legacy/Button/Button";
@import "../js/smartComponents/legacy/Select/Select";
@import "../js/smartComponents/LoadingPlaceholder/LoadingPlaceholder";
@import "../js/smartComponents/MarketplaceCta/MarketplaceCta";
@import "../js/smartComponents/NoteComponents/NoteComponents";
@import "../js/smartComponents/PrerequisiteFailureSummary/PrerequisiteFailureSummary";
@import "../js/smartComponents/SavingThrowsSummary/SavingThrowsSummary";
@import "../js/smartComponents/Snippet/Snippet";
@import "../js/smartComponents/SpellDamageEffect/SpellDamageEffect";
@import "../js/smartComponents/XpBar/XpBar";

/** Shared **/
@import "./character-tools/shared/components/action-detail.scss";
@import "./character-tools/shared/components/character-portrait.scss";
@import "./character-tools/shared/components/class-spell-manager.scss";
@import "./character-tools/shared/components/condition-levels-table.scss";
@import "./character-tools/shared/components/custom-attack.scss";
@import "./character-tools/shared/components/customize-data-editor.scss";
@import "./character-tools/shared/components/custom-item-creator.scss";
@import "./character-tools/shared/components/detail-choice.scss";
@import "./character-tools/shared/components/dice-adjustment-summary.scss";
@import "./character-tools/shared/components/cta-preference-manager";
@import "./character-tools/shared/components/container-manager";
@import "./character-tools/shared/components/editor-box.scss";
@import "./character-tools/shared/components/equipment-shop.scss";
@import "./character-tools/shared/components/error-boundary.scss";
@import "./character-tools/shared/components/full-screen-notification";
@import "./character-tools/shared/components/infusion-choice-manager";
@import "./character-tools/shared/components/item-detail-abilities.scss";
@import "./character-tools/shared/components/item-detail.scss";
@import "./character-tools/shared/components/item-list-information-collapsible.scss";
@import "./character-tools/shared/components/item-slot-manager.scss";
@import "./character-tools/shared/components/natural-attack-detail.scss";
@import "./character-tools/shared/components/restore-life.scss";
@import "./character-tools/shared/components/sidebar.scss";
@import "./character-tools/shared/components/simple-quantity.scss";
@import "./character-tools/shared/components/slot-manager-large.scss";
@import "./character-tools/shared/components/slot-manager.scss";
@import "./character-tools/shared/components/spell-manager.scss";
@import "./character-tools/shared/components/spell-slot-manager.scss";
@import "./character-tools/shared/components/starting-equipment.scss";
@import "./character-tools/shared/components/suggestions-table.scss";
@import "./character-tools/shared/components/theme-button.scss";
@import "./character-tools/shared/components/theme-button-with-menu";
@import "./character-tools/shared/global/character-tools.scss";
@import "character-tools/shared/panes/decoration-manager";
@import "./character-tools/shared/panes/condition-manage-pane.scss";
@import "./character-tools/shared/panes/container-pane";
@import "./character-tools/shared/panes/currency-pane.scss";
@import "./character-tools/shared/panes/custom-action-pane.scss";
@import "./character-tools/shared/panes/custom-actions-pane.scss";
@import "./character-tools/shared/panes/custom-skill-pane.scss";
@import "./character-tools/shared/panes/defense-manage-pane.scss";
@import "./character-tools/shared/panes/decorate-pane.scss";
@import "./character-tools/shared/panes/description-pane.scss";
@import "./character-tools/shared/panes/encumbrance-pane.scss";
@import "./character-tools/shared/panes/equipment-manage-pane.scss";
@import "./character-tools/shared/panes/export-pdf-pane";
@import "./character-tools/shared/panes/extra-manage-pane.scss";
@import "./character-tools/shared/panes/infusion-choice-pane";
@import "./character-tools/shared/panes/item-pane.scss";
@import "./character-tools/shared/panes/note-manage-pane.scss";
@import "./character-tools/shared/panes/preferences-pane.scss";
@import "./character-tools/shared/panes/proficiencies-pane.scss";
@import "./character-tools/shared/panes/reset-pane.scss";
@import "./character-tools/shared/panes/saving-throws-pane.scss";
@import "./character-tools/shared/panes/sense-manage-pane.scss";
@import "./character-tools/shared/panes/share-url-pane.scss";
@import "./character-tools/shared/panes/skill-pane.scss";
@import "./character-tools/shared/panes/skills-pane.scss";
@import "./character-tools/shared/panes/speed-manage.pane.scss";
@import "./character-tools/shared/panes/spell-manage-pane.scss";
@import "./character-tools/shared/panes/spell-pane.scss";
@import "./character-tools/shared/panes/trait-pane.scss";
@import "./character-tools/shared/panes/vehicle-pane.scss";
@import "./character-tools/shared/panes/vehicle-action-station-pane.scss";
@import "./character-tools/shared/panes/vehicle-component-pane.scss";

/** Sheet **/
@import "./character-tools/sheet/_components/ability-pool.scss";
@import "./character-tools/sheet/_components/attuned-slots.scss";
@import "./character-tools/sheet/_components/background.scss";
@import "./character-tools/sheet/_components/basic-actions.scss";
@import "./character-tools/sheet/_components/character-header.scss";
@import "./character-tools/sheet/_components/character-header-desktop.scss";
@import "./character-tools/sheet/_components/character-header-info.scss";
@import "./character-tools/sheet/_components/character-header-mobile.scss";
@import "./character-tools/sheet/_components/character-header-tablet.scss";
@import "./character-tools/sheet/_components/character-sheet-desktop.scss";
@import "./character-tools/sheet/_components/character-sheet-mobile.scss";
@import "./character-tools/sheet/_components/character-sheet-tablet.scss";
@import "./character-tools/sheet/_components/character-sheet.scss";
@import "./character-tools/sheet/_components/class-detail.scss";
@import "./character-tools/sheet/_components/class-feature-detail.scss";
@import "./character-tools/sheet/_components/class-feature.scss";
@import "./character-tools/sheet/_components/combat-mobile.scss";
@import "./character-tools/sheet/_components/combat-tablet.scss";
@import "./character-tools/sheet/_components/component-carousel.scss";
@import "./character-tools/sheet/_components/conditions-summary.scss";
@import "./character-tools/sheet/_components/content-group.scss";
@import "./character-tools/sheet/_components/creature-detail.scss";
@import "./character-tools/sheet/_components/currency-button.scss";
@import "./character-tools/sheet/_components/extras-filter.scss";
@import "./character-tools/sheet/_components/extra-list.scss";
@import "./character-tools/sheet/_components/extra-row.scss";
@import "./character-tools/sheet/_components/defenses-summary.scss";
@import "./character-tools/sheet/_components/equipment-overview.scss";
@import "./character-tools/sheet/_components/feats-detail.scss";
@import "./character-tools/sheet/_components/feature-snippet.scss";
@import "./character-tools/sheet/_components/filter-constraints.scss";
@import "./character-tools/sheet/_components/forms.scss";
@import "./character-tools/sheet/_components/health-adjuster.scss";
@import "./character-tools/sheet/_components/invalid-character.scss";
@import "./character-tools/sheet/_components/inventory-filter.scss";
@import "./character-tools/sheet/_components/inventory-item.scss";
@import "./character-tools/sheet/_components/listing-pager.scss";
@import "./character-tools/sheet/_components/loading-blocker.scss";
@import "./character-tools/sheet/_components/menus.scss";
@import "./character-tools/sheet/_components/mobile-divider.scss";
@import "./character-tools/sheet/_components/other-possessions.scss";
@import "./character-tools/sheet/_components/proficiency-bonus-box.scss";
@import "./character-tools/sheet/_components/proficiency-groups.scss";
@import "./character-tools/sheet/_components/quick-info.scss";
@import "./character-tools/sheet/_components/quick-nav.scss";
@import "./character-tools/sheet/_components/racial-trait-detail.scss";
@import "./character-tools/sheet/_components/racial-trait.scss";
@import "./character-tools/sheet/_components/saving-throws-details.scss";
@import "./character-tools/sheet/_components/section-placeholder.scss";
@import "./character-tools/sheet/_components/senses.scss";
@import "./character-tools/sheet/_components/skills.scss";
@import "./character-tools/sheet/_components/source-list.scss";
@import "./character-tools/sheet/_components/speed-box.scss";
@import "./character-tools/sheet/_components/spell-caster.scss";
@import "./character-tools/sheet/_components/spell-detail.scss";
@import "./character-tools/sheet/_components/spell-notes.scss";
@import "./character-tools/sheet/_components/spells-filter.scss";
@import "./character-tools/sheet/_components/spells-slot-chooser.scss";
@import "./character-tools/sheet/_components/spells-spell.scss";
@import "./character-tools/sheet/_components/status-summary-mobile.scss";
@import "./character-tools/sheet/_components/subsection-group.scss";
@import "./character-tools/sheet/_components/subsection-mobile.scss";
@import "./character-tools/sheet/_components/subsection-tablet.scss";
@import "./character-tools/sheet/_components/subsections.scss";
@import "./character-tools/sheet/_components/sync-blocker.scss";
@import "./character-tools/sheet/_components/tablet-box.scss";
@import "./character-tools/sheet/_components/tablet-group.scss";
@import "./character-tools/sheet/_components/trait-content.scss";
@import "./character-tools/sheet/_components/value-editor.scss";
@import "./character-tools/sheet/_components/vehicle-block.scss";
@import "./character-tools/sheet/_components/vehicle-block-action-station.scss";
@import "./character-tools/sheet/_components/vehicle-block-component.scss";
@import "./character-tools/sheet/_components/vehicle-condition-tracker.scss";
@import "./character-tools/sheet/_components/vehicle-health-adjuster.scss";
@import "./character-tools/sheet/_components/vehicle-fuel-tracker.scss";
@import "./character-tools/sheet/_components/weapon-detail.scss";
@import "./character-tools/sheet/_components/modals/equipment-manager.scss";
@import "./character-tools/sheet/app/app.scss";
@import "./character-tools/sheet/legacy/_components/spell-manager.scss";
@import "./character-tools/sheet/legacy/_components/subsection.scss";
@import "./character-tools/sheet/legacy/_components/common/collapsible.scss";
@import "./character-tools/sheet/legacy/_components/common/prop-list.scss";
@import "./character-tools/sheet/legacy/encumbrance-details.scss";
@import "./character-tools/sheet/legacy/equipment.scss";
@import "./character-tools/sheet/legacy/spells.scss";
@import "./character-tools/sheet/subsections/actions-mobile.scss";
@import "./character-tools/sheet/subsections/actions-tablet.scss";
@import "./character-tools/sheet/subsections/actions.scss";
@import "./character-tools/sheet/subsections/attunement.scss";
@import "./character-tools/sheet/subsections/classes-detail.scss";
@import "./character-tools/sheet/subsections/combat.scss";
@import "./character-tools/sheet/subsections/extras-mobile.scss";
@import "./character-tools/sheet/subsections/extras.scss";
@import "./character-tools/sheet/subsections/description-mobile.scss";
@import "./character-tools/sheet/subsections/description-tablet.scss";
@import "./character-tools/sheet/subsections/description.scss";
@import "./character-tools/sheet/subsections/equipment-mobile.scss";
@import "./character-tools/sheet/subsections/equipment.scss";
@import "./character-tools/sheet/subsections/features-mobile.scss";
@import "./character-tools/sheet/subsections/features.scss";
@import "./character-tools/sheet/subsections/infusions";
@import "./character-tools/sheet/subsections/inventory.scss";
@import "./character-tools/sheet/subsections/limited.scss";
@import "./character-tools/sheet/subsections/main-mobile.scss";
@import "./character-tools/sheet/subsections/main-tablet.scss";
@import "./character-tools/sheet/subsections/notes-mobile.scss";
@import "./character-tools/sheet/subsections/notes-tablet.scss";
@import "./character-tools/sheet/subsections/notes.scss";
@import "./character-tools/sheet/subsections/primary-box.scss";
@import "./character-tools/sheet/subsections/proficiency-groups-box.scss";
@import "./character-tools/sheet/subsections/proficiency-groups-mobile.scss";
@import "./character-tools/sheet/subsections/saving-throws-box.scss";
@import "./character-tools/sheet/subsections/senses-box.scss";
@import "./character-tools/sheet/subsections/senses-desktop.scss";
@import "./character-tools/sheet/subsections/skills-box.scss";
@import "./character-tools/sheet/subsections/skills-mobile.scss";
@import "./character-tools/sheet/subsections/spells-level-casting.scss";
@import "./character-tools/sheet/subsections/spells-level.scss";
@import "./character-tools/sheet/subsections/spells-mobile.scss";
@import "./character-tools/sheet/subsections/spells.scss";

/** Builder **/
@import "./character-tools/builder/app.scss";
@import "./character-tools/builder/components/builder-field.scss";
@import "./character-tools/builder/components/builder-message.scss";
@import "./character-tools/builder/components/builder-sections.scss";
@import "./character-tools/builder/components/class-manager.scss";
@import "./character-tools/builder/components/class-simple.scss";
@import "./character-tools/builder/components/help-text-manager.scss";
@import "./character-tools/builder/components/optional-feature.scss";
@import "./character-tools/builder/components/progression-manager.scss";
@import "./character-tools/builder/components/race-simple.scss";
@import "./character-tools/builder/components/todo-navigation.scss";
@import "./character-tools/builder/pages/ability-scores-manage.scss";
@import "./character-tools/builder/pages/classes-manage.scss";
@import "./character-tools/builder/pages/description-manage.scss";
@import "./character-tools/builder/pages/equipment-manage.scss";
@import "./character-tools/builder/pages/home-manage.scss";
@import "./character-tools/builder/pages/manage-race.scss";
@import "./character-tools/builder/pages/optional-feature-manager.scss";
@import "./character-tools/builder/pages/whats-next.scss";

/** Dice **/
@import "~@dndbeyond/dice/dist/css/animate.css";
@import "~@dndbeyond/dice/dist/css/notifications.css";
@import "~@dndbeyond/dice-components/dist/css/diceComponents.css";
@import "./character-tools/dice/dice";

/** Overrides **/
@import "./character-tools/overrides/tools";
