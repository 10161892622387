.confirmModal {
  border-radius: 0;
  padding: 0;
  max-width: 31.25rem;
  width: 100%;
  height: 70%;
}

.confirmModal.fullScreen {
  height: 100vh;
  max-height: none;
  max-width: 100%;
}

@supports (max-height: 100dvh) {
  .confirmModal.fullScreen {
    height: 100dvh;
  }
}

.confirmModal.fit-content {
  height: fit-content;
}

.confirmModal[open] {
  display: flex;
  flex-direction: column;
}

.header {
  background: var(--ttui_grey-800);
  color: var(--ttui_common-0);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.937rem;
  flex-shrink: 0;
  font-family: var(--font-condensed);
}

.header h2 {
  margin: 0;
}

.header .closeIcon:only-child {
  fill: var(--ttui_common-0);
  width: 1.25rem;
  height: 1.25rem;
}

.remove .header {
  background: var(--ttui_color-secondary--main);
}

.closeButton:focus-visible {
  outline: 0.125rem solid var(--ttui_common-0);
}

.content {
  padding: 1.25rem;
  overflow-y: auto;
  flex-grow: 1;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  flex-shrink: 0;
  border-top: 0.0625rem solid var(--ttui_grey-200);
}

[class*="Button"].footerButton {
  border-radius: 0;
  color: var(--ttui_common-0);
  font-family: var(--font-condensed);
  font-weight: bold;
}

.footerButton.confirmOnly {
  justify-self: flex-end;
  width: 33%;
  border-radius: 0.25rem;
  margin: 0.25rem;
}

/* Focus-visible styles - should be handled in TTUI but adding here for now */
[class*="ButtonVariants_success"].footerButton:focus-visible {
  background-color: var(--ttui_color-success--dark);
  border-color: var(--ttui_color-success--dark);
}
[class*="ButtonVariants_primary"].footerButton:focus-visible {
  background-color: var(--ttui_color-primary--dark);
  border-color: var(--ttui_color-primary--dark);
}
[class*="ButtonVariants_secondary"].footerButton:focus-visible {
  background-color: var(--ttui_color-secondary--dark);
  border-color: var(--ttui_color-secondary--dark);
}

@media screen and (min-width: 768px) {
  .confirmModal.fullScreen {
    height: 70%;
    width: 31.25rem;
  }
}
