/* Make icons bigger for concentration, attunement, etc */
.spell summary [data-tooltip-content] > svg {
  width: 1rem;
  height: 1rem;
  margin-left: 0.188rem;
}

.callout {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0;
}

.callout > span:first-child {
  font-size: 0.625rem;
  text-transform: uppercase;
}
