.container {
  display: flex;
  align-items: center;
}

.startingClass {
  color: var(--character-muted-color);
  font-size: 0.75rem;
  font-weight: normal;
  position: absolute;
  left: 50%;
  top: -0.75rem;
  transform: translate(-50%);
}

.classImg {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.1875rem;
  margin-bottom: 0.375rem;
  margin-right: 0.625rem;
}
.nameGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.name {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: var(--font-condensed);
  line-height: 1.1;
  display: flex;
  align-items: center;
}

.subclassName {
  font-size: 0.875rem;
  line-height: 1.1;
}

.levelManager {
  display: flex;
  align-items: center;
}

.levelManager select {
  height: auto;
  width: 3.125rem;
}

.levelManager .todoIcon {
  background: var(--character-builder-blue);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  z-index: 3;
  color: var(--ttui_common-0);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.levelLabel {
  font-weight: bold;
  margin-right: 0.625rem;
  position: relative;
  gap: 0.25rem;
  display: flex;
  align-items: center;
}

.removeClassButton .icon:only-child {
  fill: var(--ttui_red-600);
  width: 1.25rem;
  height: 1.25rem;
}

.modalIntro {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}

.modalLevel {
  font-weight: bold;
}

@media (min-width: 768px) {
  .modalLevels {
    display: flex;
  }

  .modalLevel:first-child {
    margin-right: 0.625rem;
    padding-right: 0.625rem;
    border-right: 0.125rem solid var(--ttui_grey-200);
  }
}
