@import "@dndbeyond/ttui/components/Button/styles/Button.module.css";

.speciesChoose {
  padding: 0.75rem 0.938rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0.625rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.label {
  font-family: var(--font-condensed);
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.legacy {
  margin: 0.5rem 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.legacy label {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.search {
  margin-bottom: 0.5rem;
}

.accordion > div {
  padding: 0 1rem 1rem;
}

.accordion > summary > div > svg {
  fill: var(--ttui_grey-700);
  margin: unset;
  width: 1.25rem;
  height: 100%;
  flex: 0 0 1.25rem;
}

.accordion[class*="_text"] {
  border-bottom: 0.0625rem solid var(--ttui_grey-200);
  border-radius: 0;
  margin-bottom: 0;
}

.accordion > summary:focus-visible {
  border: 0.125rem solid var(--ttui_blue-400);
  border-radius: 0.5rem;
}

.heading {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.text {
  margin: 0;
  color: var(--ttui_grey-600);
  font-size: 0.938rem;
  font-weight: 500;
  line-height: 1.5;
}

.marketplace {
  margin-bottom: 1.25rem;
}

.divider {
  border: 0.0313rem solid var(--ttui_grey-200);
  margin: 1.25rem 0;
}

.infoIcon {
  cursor: help;
  fill: var(--ttui_grey-500);
}

.notFound {
  background: var(--ttui_grey-100);
  border: 0.125rem dashed var(--ttui_grey-200);
  color: var(--ttui_grey-500);
  font-weight: 700;
  padding: 0.625rem;
  text-align: center;
}

.collapseExpand {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.3125rem;
}

.collapseExpandButton {
  padding: 0.1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: var(--font-condensed);
}
