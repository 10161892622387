.hpSummaryContent {
  display: flex;
  justify-content: space-between;
}

.hpSummaryContent p {
  margin: 0;
}

.label {
  font-weight: bold;
  margin-right: 0.4375rem;
  font-family: var(--font-condensed);
}

.button {
  font-size: 0.75rem;
  font-family: var(--font-condensed);
}

@media (min-width: 768px) {
  .hpSummaryContent {
    margin: 0;
    background: var(--ttui_common-0);
    border: 0.0625rem solid var(--ttui_grey-200);
    padding: 0.625rem;
    border-radius: 0.1875rem;
    align-items: center;
    gap: 0.9375rem;
  }
}
