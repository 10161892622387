.classes-manage {
  &-primary {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .progression-manager {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
    margin-bottom: 20px;
    align-items: center;
  }

  &-actions {
    &-action {
      color: $builder-default-color;
      cursor: pointer;
      display: inline;

      &-disabled {
        color: #ccc;
        cursor: default;
      }
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    .progression-manager {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    &-primary {
      display: flex;
      align-items: center;

      &-section {
        &-progression {
          flex: 1;
          padding-right: 30px;
        }
      }
    }
  }
}
