.container {
  background: var(--ttui_common-0);
  border-radius: 0.1875rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  margin: 0.625rem 0;
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-family: var(--font-condensed);
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

.preview {
  padding: 0.625rem 0 0;
  white-space: pre-wrap;
}

.title {
  font-family: var(--font-condensed);
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 0.625rem;
}

.title > * {
  margin: 0 0 0.375rem;
  font-family: var(--font-condensed);
  font-size: 0.875rem;
}

.textarea > textarea,
.textarea:after {
  font-family: var(--font-sans);
  font-size: 0.9375rem;
  line-height: 1.4063rem;
}

.extra p {
  font-size: 0.9375rem;
  line-height: 1.5;
}
