.hpManageModal {
  font-size: 0.9375rem;
}
.total {
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.totalLabel {
  font-size: 0.75rem;
  color: var(--ttui_grey-400);
  text-transform: uppercase;
}

.totalValue {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1;
}

.controls {
  display: grid;
  max-width: 21.875rem;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.75rem;
}

.controls :global(.builder-field-value) {
  text-align: center;
}

.baseHp {
  margin: 1.5625rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.baseHpLabel {
  margin-top: 0.1875rem;
  font-family: var(--font-condensed);
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
}

.baseHpValue {
  font-size: 1.25rem;
  line-height: 2;
}

.hpSources {
  margin-bottom: 1.25rem;
}

.hpSources h3 {
  font-size: 0.9375rem;
  margin: 0;
}

.hpSources p {
  margin: 0 0 0.25rem;
}

.info {
  display: flex;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-top: 0.0625rem solid var(--ttui_grey-200);
  border-bottom: 0.0625rem solid var(--ttui_grey-200);
}

.info h3,
.help h3 {
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-weight: 400;
}

.infoGroup {
  width: 50%;
}

.infoLabel {
  font-weight: bold;
  margin-right: 0.4375rem;
  font-family: var(--font-condensed);
}
